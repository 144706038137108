import React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Banner from "../components/Banner";

export default function PrivacyPolicy() {
  return (
    <Layout>
      <Seo
        title="Privacy Policy"
        url="https://migrantresilience.org/privacy-policy"
      />
      <div className="bg-sectionBlue w-full flex items-center justify-start lg:px-28 md:px-12 px-4 lg:py-16 py-12 lg:mb-12 md:mb-6">
        <h1 className="text-left text-darkblue md:text-4xl text-2xl font-bold">
          Privacy Policy
        </h1>
      </div>
      <div className="staticPage w-full flex flex-col justify-center items-start relative z-0 lg:px-28 md:px-12 px-4 overflow-hidden lg:pb-10 pb-6 pt-6 lg:pt-0">
        <p>
          This Privacy Notice outlines Jan Sahas’s approach to privacy to
          fulfill its obligations under applicable privacy laws.
        </p>
        <p>
          This Privacy Notice applies to all your <strong>Personal Data</strong>{" "}
          processed by us, whether in physical or electronic mode.
        </p>
        <p>
          Please note that this Privacy Notice is applicable to all instances
          where we play the role of a <strong>Data Fiduciary</strong> of your
          Personal Data, when we collect and process personal data about you for
          offering our products or services.
        </p>
        <p>
          Jan Sahas is committed to keeping your Personal Data private. We
          process any Personal Data we collect from you in accordance with the
          applicable laws and regulations and the provisions of this Privacy
          Notice. Please read the following carefully to understand our views
          and practices regarding your Personal Data and how we treat it.
        </p>

        <p>
          Throughout this document, the terms “we”, “us”, “our” &amp; “ours”
          refer to Jan Sahas. And the terms “you”, “your” &amp; “yours” refer to
          YOU (as the Data Principal).
        </p>
        <h2>What Personal Data do we Collect &amp; Process?</h2>
        <p>
          Categories of Personal Data that we collect, and process are as
          follows:
        </p>
        <ul>
          <li>
            Demographic &amp; Identity Data (for e.g., name, address, email
            address, contact number, Aadhar number, PAN details)
          </li>
          <li>Financial Data (for e.g., account details)</li>
          <li>
            Online Identifiers and other Technical Data (for e.g., IP address,
            transaction logs, device details)
          </li>
          <li>
            Profession and education (for e.g. educational qualification,
            occupation)
          </li>
          <li>
            Special categories of data (for e.g. racial or ethnic origin,
            political opinions, religious or philosophical beliefs, concerning
            health or data concerning a natural person’s sex life or sexual
            orientation, caste).
          </li>
        </ul>
        <h2>Where do we obtain your Personal Data from?</h2>
        <p>
          Most of the Personal Data we process is provided by you directly to us
          when you services, make a donation, request a communication from us,
          register for an on-ground event, pledge support to our work and
          campaigns and apply for working or volunteering. This also includes
          the Personal Data collected automatically and in the background by us
          when you use our website and application(s).
        </p>
        <p>
          We may also receive Personal Data about you from third parties and
          publicly available sources of information.
        </p>
        <h2>How do we use your Personal Data?</h2>
        <p>We use your Personal Data for the following purposes:</p>
        <ul>
          <li>To verify your identity</li>
          <li>To deliver our services</li>
          <li>
            To communicate with you regarding our services availed by you,
            including notifications of any alerts or updates
          </li>
          <li>To evaluate, develop and improve our services</li>
          <li>For analysis and research</li>
          <li>To handle enquiries and complaints</li>
          <li>To comply with legal or regulatory requirements</li>
          <li>
            To investigate, prevent, or take action regarding illegal
            activities, suspected fraud and situations involving potential
            threats to the safety of any person
          </li>
        </ul>

        <h2>Lawful Bases of processing your Personal Data</h2>
        <p>
          We process your Personal Data by relying on one or more of the
          following lawful bases:
        </p>
        <ul>
          <li>
            You have explicitly agreed to/consented to us processing your
            Personal Data for a specific reason{" "}
          </li>
          <li>The processing is necessary for employment purposes </li>
          <li>
            The processing is necessary for compliance with a legal obligation
            we have{" "}
          </li>
          <li>The processing is necessary for other reasonable purposes</li>
        </ul>
        <p>
          Where the processing is based on your consent, you have the right to
          withdraw your consent at any point in time. Please note that should
          the withdrawal of consent result in us not being able to continue
          offering our products and services to you, we reserve the right to
          withdraw or cease our products and services to you upon your
          withdrawal. You may withdraw consent by contacting us with a written
          request to the contact details specified below in the ‘Contact Us’
          section. Upon receipt of your request to withdraw your consent, the
          consequences of withdrawal will be communicated to you. Upon your
          agreement to the same, your request for withdrawal will be processed.
        </p>
        <h2>When do we share your Personal Data with third parties?</h2>
        <p>
          We may use third parties in the provision of our services to you. We
          may share your Personal Data with such third parties. We have
          appropriate contracts in place with all such third parties. This means
          that they are not permitted to do anything with your Personal Data
          which is outside of the scope specified by us. They are committed to
          hold your Personal Data securely and retain it only for the period
          specified in our contracts with them.
        </p>

        <h4>1. Reasons for sharing your Personal Data with third parties:</h4>
        <p>
          We may disclose your Personal Data to third parties only where it is
          lawful to do so. This includes instances where we or they:
        </p>
        <ul>
          <li>need to provide you with services</li>
          <li>
            have asked you for your consent to share it, and you have agreed
          </li>
          <li>have a reasonable ground for doing so</li>
          <li>
            have a legal obligation to do so. For e.g., to assist with detecting
            and preventing fraud
          </li>
          <li>
            have a requirement in connection with regulatory reporting,
            litigation or asserting or defending legal rights and interests
          </li>
        </ul>
        <p>
          We may also disclose your Personal Data to appropriate authorities if
          we believe that it is reasonably necessary to comply with a law,
          regulation, legal process; protect the safety of any person; address
          fraud, security, or technical issues; or protect our rights or the
          rights of those who use our products &amp; services.
        </p>
        <h4>2. With whom your Personal Data may be shared:</h4>
        <p>
          We may disclose your Personal Data to the following third parties:
        </p>
        <ul>
          <li>
            Our partners who help us in providing our services (for e.g. our
            training partners, medical practitioners, partner NGOs)
          </li>
          <li>
            any sub-contractors, agents, volunteers or service providers who
            work for us or provide services or products to us
          </li>

          <li>
            law enforcement authorities, government authorities, courts, dispute
            resolution bodies, regulators, auditors and any party appointed or
            requested by applicable regulators to carry out investigations or
            audits of our activities
          </li>
          <li>
            statutory and regulatory bodies, authorities (including the
            government) investigating agencies and entities or persons, to whom
            or before whom it is mandatory to disclose Personal Data as per the
            applicable law, courts, judicial and quasi-judicial authorities and
            tribunals, arbitrators and arbitration tribunals
          </li>
        </ul>
        <h2>Cross-border data transfer</h2>
        <p>
          Personal Data we hold about you may be transferred to other countries
          outside of India for any of the purposes described in this Privacy
          Notice.
        </p>

        <p>
          Any personal data will be protected in accordance with this Privacy
          Notice as well as with adequate protections in place in compliance
          with applicable laws and regulations.
        </p>
        <h2>Use of Cookies and other Tracking Mechanisms</h2>

        <p>
          We may use cookies and other tracking mechanisms on our website and
          other digital properties to collect data about you.
        </p>

        <p>
          Cookies are small text files that are placed on your computer by
          websites that you visit. They are widely used in order to make
          websites work, or work more efficiently, as well as to provide
          information about your actions to the owners of the website.
        </p>
        <p>
          Most web browsers allow you some control of cookies through browser
          settings.
        </p>
        <p>
          Outlined below are the categories of cookies along with a description
          of what they are used for.
        </p>

        <ul>
          <li>
            Strictly Necessary Cookies - These cookies are needed to run our
            website, to keep it secure and to comply with regulations that apply
            to us.
          </li>
          <li>
            Functional Cookies – We may use functional cookies on our website.
            These cookies allow us to remember information you enter or choices
            you make (such as your username, language, or your region) and
            provide you with enhanced, more personalised features.
          </li>
          <li>
            Performance/Analytics Cookies – We may use performance/analytics
            cookies on our website. These cookies collect information about how
            visitors use our website and services, including which pages
            visitors go to most often and if they receive error messages from
            certain pages. It is used to improve how our website functions and
            performs.
          </li>

          <li>
            Marketing Cookies – We may use marketing cookies on our website.
            These cookies help us decide which of our products, services and
            offers may be relevant for you. We may use this data to tailor the
            marketing and ads you see on our own and other website and mobile
            apps, including social media. For instance, you may see our ads on
            other sites after you have been to our website.
          </li>
        </ul>

        <p>
          We may also use trackers (such as web beacons, tags, pixels) on our
          website and other digital properties to collect data about you.
        </p>
        <p>
          We may also collect Personal Data about you via our mobile app(s) via
          permissions in the app. This is primarily used to enhance the
          functionality of the app and to analyse it to serve you better.
        </p>
        <h2>How do we secure your Personal Data?</h2>
        <p>
          We are committed to protecting your Personal Data in our custody. We
          take reasonable steps to ensure appropriate physical, technical and
          managerial safeguards are in place to protect your Personal Data from
          unauthorized access, alteration, transmission and deletion. We ensure
          that the third parties who provide services to us under appropriate
          contracts take appropriate security measures to protect your Personal
          Data in line with our policies.
        </p>
        <h2>How long do we keep your Personal Data?</h2>
        <p>
          We keep the Personal Data we collect about you for as long as it is
          required for the purposes set out in this Privacy Notice and for legal
          or regulatory reasons. We take reasonable steps to delete your
          Personal Data that is no longer needed.
        </p>
        <h2>Links to other Websites</h2>
        <p>
          Our website may contain links to websites of other organizations. This
          privacy notice does not cover how those organizations process your
          Personal Data. We encourage you to read the privacy notices on the
          other websites you visit.
        </p>
        <h2>Contact Us</h2>
        <p>
          For any queries and complaints related to privacy, or exercising your
          rights, you could reach us at:
        </p>
        <a href="mailto:privacy@jansahasindia.org" className="mb-4">
          Email: privacy@jansahasindia.org
        </a>
        <h2>Notification of changes</h2>
        <p>
          We regularly review and update our Privacy Notice to ensure it is
          up-to-date and accurate. Any changes we may make to this Privacy
          Notice in future will be posted on this page.
        </p>
        <h2>Your Privacy rights</h2>
        <p>
          If you are an Indian resident, under the India Personal Data
          Protection Bill 2019, you have the following rights, and we commit to
          provide you with the same:
        </p>
        <ul>
          <li>
            Right to Confirmation and Access: You have the right to get
            confirmation and access to your Personal Data that is with us along
            with other supporting information.
          </li>
          <li>
            Right to Correction: You have the right to ask us to rectify your
            Personal Data that is with us that you think is inaccurate. You also
            have the right to ask us to update your Personal Data that you think
            is incomplete or out-of-date.
          </li>
          <li>
            Right to Erasure : You have the right to ask us to erase your
            Personal Data that is with us under certain circumstances.
          </li>
          <li>
            Right to Data Portability: You have the right to ask that we
            transfer the Personal Data you gave us to another organisation, or
            to you, under certain circumstances.
          </li>
          <li>
            Right to be Forgotten: You have the right to restrict or prevent the
            continuing disclosure of your personal data under certain
            circumstances.
          </li>
          <li>
            Right to lodge a complaint with the Authority: You have the right to
            lodge a complaint with the Authority.
          </li>
        </ul>
        <p>
          If you wish to make a request to exercise any of your rights, you can
          contact us using the details in the ‘Contact us’ section of this
          notice.
        </p>
        <p>
          There should be contracts with a third party that include data privacy
          clauses if personal data is shared with that third party.
        </p>
      </div>
    </Layout>
  );
}
